import React from "react"
import Layout from "../../components/layout/Layout.js"
import { Row, BlogRow} from "../../components/row/Row.js"
import {
  BlogTitle,
  SubHeading,
} from "../../components/title/Title.js"
import { TextMedium } from "../../components/text/Text.js"
import { Column50 } from "../../components/column/Column.js"
import Seo from "../../components/seo"
import img1 from "../../static/content-marketing-plan.png"
import { Breadcrumb } from '../../components/breadcrumb/Breadcrumb'
import {
  Image,
} from "../../components/image/Image.js"
import { AboveFold, Section } from "../../components/section/Section.js"
import GumroadForm from "../../components/gumroadForm/GumroadForm.js"
import { Contact, ButtonContainer } from "../../components/button/Button.js"


function ContentPlan({ children, ...rest }) {
  return (
    <Layout>
      <Seo title="One-Year Content Marketing Strategy - Template" 
       description="This template includes target audiences, industries, themes, distribution channels, and quarterly content creation calendars."
       image={img1}
       featuredImage={img1} />

      <AboveFold isLight>
        <Row>
        <Breadcrumb>
            <li>
              <a href="/content-strategy-templates/">All templates</a>
            </li>
            <li>
              <a href="/content-strategy-templates/year-content-marketing-strategy-template/">One-Year Content Marketing Strategy - Template</a>
            </li>
          </Breadcrumb>
        </Row>
        <Row isCentered>
          <Column50>
            <BlogTitle isPrimary title="One-Year Content Marketing Strategy - Template" />
            <SubHeading subheading="This template includes target audiences, industries, themes, distribution channels, and quarterly content creation calendars." />
            <ButtonContainer>
              <Contact buttonText="Get the template" buttonLink="https://socontent.gumroad.com/l/one-year-content-marketing-plan" />
            </ButtonContainer>
          </Column50>
          <Column50>
            <Image src={img1} alt="One-Year Content Marketing Plan" />
          </Column50>
        </Row>
      </AboveFold>

      <Section>
        <BlogRow isCentered>
          <TextMedium textMedium="We’ve used this template to develop and implement content marketing plans for SaaS and service companies 
            of various sizes."/> 
          <TextMedium textMedium="As a prerequisite, you should have your brand and marketing strategy already in place, as this
              document doesn't cover the brand and marketing strategy part." />
          <GumroadForm 
                formName="SaaS Brand, Marketing and Content Strategy"
                redirectPage="https://socontent.gumroad.com/l/one-year-content-marketing-plan"
                buttonText="Get it on Gumroad"
                buttonLink="https://socontent.gumroad.com/l/one-year-content-marketing-plan"
                // checkboxText="*Your consent is required. By checking this box, you agree to be contacted by us when we publish similar content." 
            />
        </BlogRow>
      </Section>
    </Layout>
  )
}

export default ContentPlan
